import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Layer } from 'konva/lib/Layer';
import { Line } from 'konva/lib/shapes/Line';
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { PageContext } from "../resolver/PageAnnotationResolver";

@Injectable({
  providedIn: 'root'
})
export class KcBackendService {
  public static debufStoreMap: Map<string, { json: string }> = new Map<string, { json: string }>();
  public static cachedJson: Map<string, string> = new Map<string, string>();
  public static debufIntervalID: any;
  public static debufTime: number = 1000;
  public static lastLoadedDocContext: PageContext = null;

  constructor(public http: HttpClient, public authService: AuthService) { }

  private static getCachedJsonForContext(context: PageContext) {
    return KcBackendService.cachedJson.get(JSON.stringify(context));
  }

  private static setCachedJsonForContext(context: PageContext, json: string): void {
    const cache = KcBackendService.getCachedJsonForContext(context);
    if (cache != null) {
      const newObj = JSON.parse(json);
      const cacheObj = JSON.parse(cache);
      if (Object.keys(newObj).length === 0) {
        return;
      }
      if (cacheObj.updatedDate != null && cacheObj.updatedDate > newObj.updatedDate) {
        return;
      }
    }
    KcBackendService.cachedJson.set(JSON.stringify(context), json);
  }



  public save(stage: any, context: PageContext) {
    let lineLayer = stage.children[2]
    if (stage?.children?.length < 3) {
      console.warn("Stage without Line-layers", stage)
      return
    }
    lineLayer = lineLayer.toObject()

    lineLayer.updatedDate = Date.now();


    const json = JSON.stringify({
      ...lineLayer,
      children: (lineLayer as Layer).children.filter(line => (line as Line).attrs.points.length > 0)
    });

    clearInterval(KcBackendService.debufIntervalID)
    KcBackendService.setCachedJsonForContext(context, '' + json);
    KcBackendService.debufStoreMap.set(JSON.stringify(context), { json: json })
    KcBackendService.debufIntervalID = setTimeout(this.storeDebuffed.bind(this), KcBackendService.debufTime);
  }

  public async storeDebuffed() {
    KcBackendService.debufStoreMap.forEach((value, pageContextStr, map) => {
      const pageContext: PageContext = JSON.parse(pageContextStr);
      KcBackendService.debufStoreMap.delete(pageContextStr);
      this.saveInternal(value.json, pageContext);
    });
  }

  protected async saveInternal(json: string, context: PageContext) {
    const url = `/api/pdfoverlay/${context.docid}/${context.page}/${context.timeblockid}/${context.participantid}`;
    const body = {
      json
    };

    KcBackendService.setCachedJsonForContext(context, '' + json);
    const result = await firstValueFrom(this.http.post(url, body));
    return result;
  }

  public async loadJson(context: PageContext) {
    // Wenn ein anderes Document geöffnet wurde alt cache wegschmeissen
    if (KcBackendService.lastLoadedDocContext != null &&
      (KcBackendService.lastLoadedDocContext.docid !== context.docid ||
        KcBackendService.lastLoadedDocContext.timeblockid !== context.timeblockid ||
        KcBackendService.lastLoadedDocContext.participantid !== context.participantid)) {

      Array.from(KcBackendService.cachedJson.keys()).forEach(value => {
        const c: PageContext = JSON.parse(value);

        if (c.docid !== context.participantid || c.docid !== context.docid || c.timeblockid !== context.timeblockid) {
          KcBackendService.cachedJson.delete(value);
        }
      });
    }
    KcBackendService.lastLoadedDocContext = context;
    const cached = KcBackendService.getCachedJsonForContext(context);
    if (cached != null) {
      this.updateJsonCacheByGet(context);
      return JSON.parse(cached);
    }
    const result = await this.updateJsonCacheByGet(context);
    return result;
  }

  public async updateJsonCacheByGet(context: PageContext) {
    const url = `/api/jsonoverlay/${context.docid}/${context.page}/${context.timeblockid}/${context.participantid}`;
    const result: any = await firstValueFrom(this.http.get(url));
    KcBackendService.setCachedJsonForContext(context, JSON.stringify(result));
    return result;
  }

  // verrät auf welchen seiten gekrizelt wurde
  public async getAvailableJsonOverlayPageNumbers(context: PageContext) {
    const url = `/api/availablejsonoverlay/${context.docid}/${context.timeblockid}/${context.participantid}`;
    return await firstValueFrom(this.http.get(url));
  }
}
