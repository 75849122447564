<!-- PAGE HEADER -->
<div
  class="row  pageWrapperHeader  my-2 mt-4"
  style="margin:0px auto ; position:relative;top:0px ; width: 100%;"
>
  <!-- <div class="col">{{this.pageContext.page+1}} / {{pagesCount}}</div> -->

</div>

<!-- ENDE PAGE HEADER -->
<!-- PAGE  -->
<div
  [id]="'page_'+this.pageContext.page"
  class="pageWrapper "
>
  <div
    [ngClass]="{'hilightPage': this.getViewType() ===  'KONVA_VIEW_TYPE_DRAW' }"
    class="newdocViewPage"
    [id]="'page_mainWrapper_'+this.pageContext.page"
    style=" background-color: #fff; "
    (click)="this.currentPageChange.emit(this.pageContext.page)"
    #wrapperElement
  >
    <div class="text-center">
      <img
        #snapshotElement
        style="width: 100%;pointer-events: none; background-color: #fff;opacity: 0.9;"
        [ngClass]="{'d-none': this.getViewType() !=='KONVA_VIEW_TYPE_PREVIEW'}"
        [src]="this.imageObj.src"
      >
      <div
        #stageElement
        [ngClass]="{'d-none':  this.getViewType() !== 'KONVA_VIEW_TYPE_DRAW' }"
      ></div>
    </div>
  </div>
