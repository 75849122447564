import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { PackService } from './PackService';
import IpadPackage = PRM.IpadPackage;


@Injectable({
  providedIn: 'root'
})
export class PdfAnnotationService {
  constructor(private http: HttpClient, private packService: PackService, private authService: AuthService) { }

  public async storeAnnotationText(docid: string, page: number, timeblockid: string, participantid: string, annotationString: string) {
    const url = '/api/pageannotation/' + docid + '/' + page + '/' + timeblockid + '/' + participantid;
    const data = { value: annotationString };
    return firstValueFrom(this.http.post<IpadPackage>(url, data));
  }

  public async getDocAnnotations(participantid: string) {
    const url = '/api/docannotations/' + participantid;
    return firstValueFrom(this.http.get<any>(url));
  }

  public async getAnnotationText(docid: string, page: number, timeblockid: string, participantid: string) {
    const url = '/api/pageannotation/' + docid + '/' + page + '/' + timeblockid + '/' + participantid;
    return firstValueFrom(this.http.get<IpadPackage>(url));
  }

  //
  public async getAvailableAnnotationTextPageNumbers(docid: string, timeblockid: string, participantid: string) {
    const url = '/api/availablepageannotation/' + docid + '/' + timeblockid + '/' + participantid;
    return await firstValueFrom(this.http.get(url));
  }
}
