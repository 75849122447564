<!-- THUMB SIDEBAR -->
<div
  id="pagesThumbs"
  style="background-color: #FFFFFF;width:125px; position: fixed; display: inline-block;z-index:1033; overflow:auto;left:0px;border-right: 1px solid #c0c0c0;"
  [ngStyle]="{'padding-bottom': editAreaVisible? '420px' : '150px'}"
  appHeightWatcher
>
  <div
    style="background-color: rgb(255, 255, 255); height: 50px;position: fixed;width:125px;z-index:9999;border-bottom:1px solid #DEE2E2;border-right: 1px solid #DEE2E2;"
  >
    <div
      class="btn btn-sm  mx-2 mt-1 d-block"
      [ngStyle]="{
      'background-color': layoutService.activeColor.backgroundColor ,
      'top': this.layoutService.headerHeightInt + 'px',
      'z-index': 1033,
      }"
      (click)="this.closeEditor()"
    ><i class="ri-arrow-left-line"></i></div>
  </div>

  <div
    style="background-color:white; position: relative;top:50px"
    *ngFor="let item of  counter(selectedDocObject.metadata.pagescount).fill(0); let i = index"
    class="m-2 cursor-pointer"
    [ngClass]="{
      'border-selected': i == this._currentPage,
      'border-unselected': i !== this._currentPage
      }"
    (click)="this.scrollTo(i)"
  >
    <!--THUMB-->
    <img
      alt="Thumbnail"
      [id]="selectedDocObject._id + '_' +i"
      [src]="this.getPagePreviewUrl(selectedDocObject._id, i, pageContexts[i].timeblockid, pageContexts[i].participantid) | secure | async"
      class="w-100"
    >
  </div>
</div>
<!-- END THUMB SIDEBAR-->
<div appHeightWatcher>
  <div
    *ngIf="selectedDocObject"
    class="col-12"
  >
    <div
      class="row "
      style="border-top:0px solid orange;margin-left:110px"
    >
      <div
        class="col"
        style="padding-left: 0px;padding-right: 0px"
        [ngStyle]="{ 'margin-bottom': editAreaVisible ? '320px' : '80px' }"
      >
        <div class="mt-0 mr-0 ml-0 ">
          <!-- DEBUG LEISTE OBEN   -->
          <div style="border-top:0px solid red; overflow-x: scroll;margin-right: 0px;padding-right: 0px;">
            <div
              id="touchMarkerWrapper"
              style="background-color: red;"
            >
              <div
                id="touchMarker"
                style="background-color: blue;opacity: 0;width: 90%;margin: auto;"
                [ngStyle]="{
                  'max-width': maxzoom +'%',
                  'min-width': minzoom +'%'
                }"
              ></div>
            </div>
            <div
              style="width: 50%;margin-bottom: 9999px;"
              *ngIf="this.pagesNeededToScreenshot.length > 0 && pageContexts.length == loadedPages"
              class="mx-auto mt-5 "
            >

              <span>{{(requredShots - this.pagesNeededToScreenshot.length)}} / {{requredShots}}</span>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="background-color:#34588C"
                  [style.width.%]="((requredShots - this.pagesNeededToScreenshot.length) /requredShots)*100"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>




              </div>
            </div>

            <div
              id="preloader"
              *ngIf="pageContexts.length != loadedPages"
              style="width: 50%;"
              class="mx-auto mt-5"
            >
              <span>{{loadedPages}} / {{pageContexts.length}}</span>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="background-color:#34588C"
                  [style.width.%]="loadedPages / pageContexts.length   *100"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>

            </div>
            <div
              [ngClass]="{'whileLoading': pageContexts.length != loadedPages}"
              id="allPagesWrapper"
              style="
            width: 90%;margin: 0px auto"
              [ngStyle]="{
                  'max-width': maxzoom +'%',
                  'min-width': minzoom +'%'
                }"
            >
              <ng-container *ngFor="let pc of pageContexts; let i = index">

                <div class="singleKonvaCanvasWrapper ">
                  <app-konva-canvas
                    *ngIf="i-1 <= loadedPages "
                    [editable]="selectedDocObject.metadata.editable"
                    (nackedImageLoaded)="this.nackedImageLoaded()"
                    (screenShotDone)="this.onKonvaScreenShotDone($event)"
                    (currentPageChange)="this.selectPage($event)"
                    [pagesCount]="this.selectedDocObject.metadata.pagescount"
                    [pageContext]="pc"
                    [konvaJson]="konvaJsons[pc.page]"
                    [myUser]="myUser"
                    [bgImage]="'/api/pdf/getpage/' + selectedDocObject._id + '/' + pc.page | secure | async"
                  ></app-konva-canvas>
                </div>

              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="fixed-bottom position-fixed p-0 "
      style="z-index: 1900;"
    >
      <ng-container *ngIf="allCanvas.length > 0 ">
        <app-konva-paintbox
          [allCanvas]="allCanvas"
          [currentPage]="this._currentPage"
          *ngIf="allCanvas?.get(0).pencilMode"
        ></app-konva-paintbox>

      </ng-container>
      <div
        [appLayout]="layoutService.baseColorSurface"
        style="border:1px solid transparent"
      >
        <table
          cellpadding="0"
          cellspacing="0"
        >
          <tr [appLayout]="layoutService.baseColorSurface">
            <td
              width="1%"
              class="text-nowrap"
              class="text-left"
            >
              <span
                class="d-flex"
                style="position: relative; top: 5px"
              >
                <app-ri-button
                  icon="ri-arrow-left-fill"
                  [disabled]=" this._currentPage * 1 <= 0"
                  (click)="this.scrollTo( this._currentPage-1)"
                >
                </app-ri-button>
                <app-ri-button
                  icon="ri-arrow-right-fill"
                  [disabled]="
                    this._currentPage * 1 + 1 >= selectedDocObject.metadata.pagescount
                  "
                  (click)="this.scrollTo(this._currentPage+1)"
                >
                </app-ri-button>
                <span
                  i18n
                  class="pl-1 small"
                  style="line-height: 220%"
                >
                  Seite
                </span>
                {{this._currentPage}}
                <input
                  [value]="this._currentPage * 1 + 1"
                  class="text-center small ml-2"
                  style="width: 28px; height: 28px"
                  disabled
                />
                <span
                  i18n
                  class="px-1 small"
                  style="line-height: 220%"
                >
                  von
                </span>
                <span
                  class="small"
                  style="line-height: 220%"
                >{{
                  selectedDocObject.metadata.pagescount
                  }}</span>
              </span>
            </td>
            <td class="pl-1">
              <span
                class="d-flex"
                style="position: relative; top: 5px"
              >
                <span (click)="zoomOut()">
                  <app-ri-button
                    icon="ri-zoom-out-fill"
                    [disabled]="docZoom == this.minzoom"
                  >
                  </app-ri-button>

                </span>
                <span (click)="zoomIn()">
                  <app-ri-button
                    icon="ri-zoom-in-fill"
                    [disabled]="docZoom == this.maxzoom"
                  >
                  </app-ri-button>
                </span>
              </span>
            </td>
            <td
              class="text-right p-0"
              [ngClass]="{ invisible: !selectedDocObject.metadata.editable }"
            >
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="p-0 mr-2 ml-auto"
              >
                <tr>
                  <td>
                    <span
                      i18n
                      class="px-1 small text-uppercase"
                    >Stift</span>
                  </td>
                  <td>
                    <label
                      class="switch"
                      style="position: relative"
                    >
                      <ng-container *ngIf="allCanvas.length > 0">
                        <input
                          type="checkbox"
                          [ngModel]="allCanvas?.get(0).pencilMode"
                          (ngModelChange)="changePencilMode($event)"
                        />
                        <span class="slider round"></span>
                      </ng-container>
                    </label>
                  </td>
                </tr>
              </table>
            </td>
            <td
              width="1%"
              class="text-nowrap"
              [ngClass]="{ invisible: !selectedDocObject.metadata.editable }"
            >
              <div
                [appLayout]="layoutService.activeColor"
                *ngIf="selectedDocObject.metadata.editable"
                class="p-2 text-center"
                (click)="editAreaVisible = !editAreaVisible"
              >
                <span class="d-flex">
                  <i
                    class="ri-message-2-fill mr-2"
                    [appLayout]="layoutService.activeColor"
                    style="
                      -webkit-transform: scaleX(-1);
                      transform: scaleX(-1);
                      position: relative;
                      left: 10px !important;
                    "
                  ></i>
                  <i
                    class="ri-arrow-up-s-line mr-2"
                    *ngIf="!editAreaVisible"
                  ></i>
                  <i
                    class="ri-arrow-down-s-line mr-2"
                    *ngIf="editAreaVisible"
                  ></i>
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div
        class="row"
        [appLayout]="layoutService.baseColorSurface"
      >
        <div
          class="col-8 offset-2 px-2"
          *ngIf="editAreaVisible"
        >
          <div style="background-color: #fff; color: #000; margin: 0px 3px">
            <quill-editor
              class="w-100"
              *ngIf="selectedDocObject.metadata.editable; else notEditable"
              placeholder=""
              [ngModel]="this.bindedAnnotations[this._currentPage]"
              (ngModelChange)="cbtest($event)"
            >
            </quill-editor>

            <ng-template #notEditable>
              <div style="height: 200px">
                <br />
                <h4
                  class="text-center"
                  i18n
                  style="color: #000"
                >
                  Notizfunktion ist deaktiviert
                </h4>
              </div>
            </ng-template>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>
