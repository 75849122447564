<ng-template #noDocs>
  <h5
    class="text-left col-12 px-4 my-4"
    i18n
  >Keine Dokumente</h5>
</ng-template>
<div
  class="row m-5"
  *ngIf="documents.length > 0; else noDocs"
>
  <div
    *ngFor="let doc of documents"
    [ngClass]="{
      'col-3': docsPerRow == 4,
      'col-4': docsPerRow == 3,
      'col-2': docsPerRow == 6
    }"
    style="align-self: flex-end; padding-bottom: 74px;"
  >
    <a
      class="d-block h-100 text-center doclink"
      #docElement
      (click)="documentClick(docElement)"
      [routerLink]="getRouterlinkToSingleDocView(doc)"
    >
      <div *ngIf="!doc.metadata.participantInstanceEnabled"></div>


      <!-- IMAGE -->
      <ng-container *ngIf="this.docsAreLocked()">
        <div style="position: relative;">
          <div
            [ngClass]="{'lockForDoc': (doc.contentType === 'application/pdf'), 'lockForVideo': !(doc.contentType === 'application/pdf')}"
          >
            <svg
              style="top: 50%; transform: translateY(-50%); position: relative;"
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              viewBox="0 -256 1792 1792"
              id="svg2989"
              version="1.1"
              inkscape:version="0.48.3.1 r9886"
              width="50%"
              height="50%"
              sodipodi:docname="lock_font_awesome.svg"
            >
              <g
                transform="matrix(1,0,0,-1,318.91525,1346.1695)"
                id="g2991"
              >
                <path
                  d="m 704,512 q 0,53 -37.5,90.5 Q 629,640 576,640 523,640 485.5,602.5 448,565 448,512 q 0,-37 19,-67 19,-30 51,-47 L 449,169 q -5,-15 5,-28 10,-13 26,-13 h 192 q 16,0 26,13 10,13 5,28 l -69,229 q 32,17 51,47 19,30 19,67 z M 320,768 h 512 v 192 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 V 768 z m 832,-96 V 96 Q 1152,56 1124,28 1096,0 1056,0 H 96 Q 56,0 28,28 0,56 0,96 v 576 q 0,40 28,68 28,28 68,28 h 32 v 192 q 0,184 132,316 132,132 316,132 184,0 316,-132 132,-132 132,-316 V 768 h 32 q 40,0 68,-28 28,-28 28,-68 z"
                  id="path2993"
                  inkscape:connector-curvature="0"
                  [ngStyle]="{'fill': layoutService.activeColor.backgroundColor}"
                />
              </g>
            </svg>
          </div>
          <img
            alt="Thumbnail"
            *ngIf="doc.metadata.type !== 'vimeo'"
            class="img-fluid img-thumbnail"
            style="background-color: #fff; filter: blur(3px);"
            [src]="'/api/document/preview/' + doc._id | secure | async"
          />
          <img
            alt="Vimeo"
            *ngIf="doc.metadata.type === 'vimeo'"
            class="img-fluid img-thumbnail"
            style="background-color: #fff;"
            [src]="vimeoService.getVimenoThumbnail(doc.metadata.vimeovideo)"
          >
        </div>
      </ng-container>

      <ng-container *ngIf="!this.docsAreLocked()">
        <div style="position: relative;">
          <div
            class="unlocked"
            *ngIf="doc.contentType === 'application/pdf' && this.showDocBlured()"
          >
            <i
              class="ri-eye-off-line"
              style="font-size: 50px !important; color: rgb(35, 46, 85); top: 50%; transform: translateY(-50%); position: relative;"
            ></i>
          </div>
          <img
            alt="Thumbnail"
            *ngIf="doc.metadata.type !== 'vimeo'"
            class="img-fluid img-thumbnail"
            style="background-color: #fff; filter: blur(3px);"
            [ngStyle]="{'filter': showDocBlured() ?  'blur(3px)' : 'none'}"
            [src]="'/api/document/preview/' + doc._id | secure | async"
          />
          <img
            alt="Vimeo"
            *ngIf="doc.metadata.type === 'vimeo'"
            class="img-fluid img-thumbnail"
            style="background-color: #fff;"
            [src]="vimeoService.getVimenoThumbnail(doc.metadata.vimeovideo)"
          >
        </div>
      </ng-container>
      <!-- IMAGE -->
      <span
        [appLayout]="layoutService.textColor"
        class=""
      >
        <span class="doclable cut-text w-75">
          {{ doc.metadata.docname }}
        </span>
        <a
          *ngIf="doc.metadata.downloadable"
          (click)="$event.stopPropagation()"
          [href]="getDownloadLink(doc._id, doc.metadata.timeblock?.toString() ?? 'introduction') | secure | async"
          [download]="doc.metadata.docname + '.pdf'"
          target="_blank"
        ><i
            class="ri-download-cloud-2-line"
            style="position: relative;top:1px;left:3px"
          ></i>
        </a>
      </span>
      <div
        *ngIf="doc.metadata.participantInstanceEnabled"
        [appLayout]="layoutService.textColor50"
      >
        <ng-container *ngIf="doc.metadata.participantObject">
          {{ doc.metadata.participantObject.fullNameWithIndex }}
        </ng-container>
        <ng-container *ngIf="!doc.metadata.participantObject">
          Could not load participant!
        </ng-container>
      </div>
      <div *ngIf="!doc.metadata.participantInstanceEnabled"><br /></div>
    </a>
  </div>
</div>