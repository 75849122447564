import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { Subject, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SocketService } from './SocketService';

export interface EndTime {
  endtime: string;
  servertime: string;
}


@Injectable({
  providedIn: 'root'
})
export class PreptimeService {
  private static eventIdToEndTime: Map<string, EndTime>;
  public mapChanged: Subject<boolean>;
  private static currentDiff = 0;

  constructor(private authService: AuthService, private http: HttpClient, private socket: SocketService) {
    PreptimeService.eventIdToEndTime = new Map<string, EndTime>();
    this.mapChanged = new Subject();
    this.socket.socket.on('preptimesupdate', this.fetchEntries.bind(this));
    this.fetchEntries();
  }

  private async fetchEntries() {
    const url = '/api/preptime/get';
    const entries = await firstValueFrom(this.http.get(url)) as PRM.PrepTimeEntry[];
    PreptimeService.eventIdToEndTime.clear();
    entries.forEach(e => {
      PreptimeService.eventIdToEndTime.set(e.eventid, { endtime: e.end, servertime: e.servertime });
      PreptimeService.currentDiff = new Date(e.servertime).getTime() - new Date().getTime();
    });
    setTimeout(this.changeSubject.bind(this), 1000);
  }

  public getEndtimeForEventId(eventid: string): EndTime {
    if (PreptimeService.eventIdToEndTime.size > 0 && PreptimeService.eventIdToEndTime.has(eventid)) {
      return PreptimeService.eventIdToEndTime.get(eventid);
    }
    return { endtime: '0', servertime: '0' };
  }

  public startPrepTime(eventid: string) {
    const url = '/api/preptime/start/' + eventid;
    return firstValueFrom(this.http.get(url));
  }

  public hasEventId(eventId: string) {
    console.log("EToEndMap" + PreptimeService.eventIdToEndTime.size)
    return PreptimeService.eventIdToEndTime.has(eventId);
  }

  public init() {
    return true;
  }

  private changeSubject() {
    this.mapChanged.next(true);
  }

  public getMap(): Map<string, EndTime> {
    return PreptimeService.eventIdToEndTime;
  }

  public getDiff(): number {
    console.log("Preptimedifff" + PreptimeService.currentDiff)
    return PreptimeService.currentDiff;
  }
}