<ng-template #confirmLogout>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide();"
    >
      <span
        aria-hidden="true"
        class="visually-hidden"
      >&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span i18n>
      Bist du dir sicher, dass du alle Beobachtenden und Teilnehmenden ausloggen willst? Nicht gespeicherte Daten gehen
      verloren!
    </span>
    <div class="text-center my-3">
      <div
        class="btn btn-primary m-2"
        (click)="modalRef?.hide();"
        i18n
      >Nein</div>
      <div
        class="btn btn-danger m-2"
        (click)="moderatorForceLogout(); this.modalRef.hide()"
        i18n
      >Ja</div>
    </div>
  </div>
</ng-template>

<div
  class="row "
  style="position: absolute;
            z-index: 9999;
            margin: 0px auto;
            width: 100%;
            pointer-events: none;"
  *ngIf="!socketService.connected"
>
  <div class="col text-center p-5 mt-3">
    <span
      class="alert alert-danger p-5"
      i18n
    >Ihre Internetverbindung ist möglicherweise getrennt worden</span>
  </div>
</div>
<nav
  #header
  id="headernavbar"
  class="header navbar navbar-expand justify-content-between navbar-light py-0 border-bottom"
  style=" background-color: #fff;"
>
  <div>
    <ng-template #nobrand>
      <img
        class="
  navbar-prm-logo"
        alt="Profil M"
        src="assets/ProfilM_Logo.png"
      >
    </ng-template>
    <a
      class="navbar-brand"
      href="{{brandlink}}"
      *ngIf="brand || brandlink; else nobrand"
    >
      {{brand}}
    </a>
  </div>

  <div>
    <ul class="navbar-nav">
      <ng-container *ngFor="let item of menuItems">
        <li
          *ngIf="!((item.routerLink == 'conference' && pack.disableReportView) || (item.routerLink == 'preptimes' && $any(pack.me).role != 'moderator') || (item.routerLink == 'videoupload' && !pack.participantVideoByModerator))"
          class="nav-item p-4"
          [routerLink]="item.routerLink"
          [routerLinkActiveOptions]="{exact:false}"
          [appLayout]="layoutService.activeColor"
          routerLinkActive="active"
          style="cursor: pointer"
        >
          <i class="{{item.text}} pr-2"></i>
          <br>
          <span
            i18n
            class="nav-item-label"
          >{{item.label}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="router.url.includes('participant')">
    <ng-container *ngFor="let entry of urlStringToEndTime | keyvalue">
      <app-preptime-countdown
        (done)="preparePrepTimes()"
        [endTime]="entry.value.endtime"
        [url]="entry.key"
        [emitDone]="prepTimeDone"
      >
      </app-preptime-countdown>
    </ng-container>
  </div>
  <div *ngIf="isModerator">
    <ng-container *ngIf="this.pack.accessValidation == 'liveauth' && this.pack.enableModeratorChangeAccessValidation">
      <div
        class="btn btn-outline-danger d-inline-flex align-items-center"
        (click)="changeAuthAccess('disabled')"
        tooltip="Login gesperrt"
        i18n-tooltip
      >
        <i class="ri-lock-line"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="this.pack.accessValidation == 'disabled' && this.pack.enableModeratorChangeAccessValidation">
      <div
        class="btn btn-outline-success d-inline-flex align-items-center"
        (click)="changeAuthAccess('liveauth')"
        tooltip="Login freigegeben"
        i18n-tooltip
      >
        <i class="ri-lock-unlock-line"></i>
      </div>
    </ng-container>
  </div>
  <div>
    <table>
      <tr>
        <td class="text-right pr-2">
          <b class="name-display">{{myUser.firstname}} <br> {{myUser.lastname}}</b>
          <br>
          <span class="small"> {{currentDate}}</span>
        </td>
        <td>
          <app-user-image-and-name-display
            [showName]=false
            [participant]="myUser"
          ></app-user-image-and-name-display>
        </td>
        <td>
          <div class="btn-group">
            <i
              class="ri-arrow-down-s-line "
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="cursor: pointer"
            ></i>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                i18n
                class="dropdown-item text-danger"
                href="#"
                (click)="logout()"
              >Abmelden</a>
              <a
                *ngIf="this.isModerator"
                class="dropdown-item text-danger"
                i18n
                (click)="this.modalRef = this.modalService.show(confirmLogout)"
              >
                Abmelden (Beos + TN)
              </a>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</nav>

<app-timezone-modal></app-timezone-modal>